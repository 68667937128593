<template>
  <div id="printMe">
    <div class="flex flex-col xl:grid grid-cols-5 gap-5 print">
      <div class="flex items-center justify-center col-span-5 space-x-2">
        <button @click="previousMonth">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <datepicker
          style="cursor: pointer !important; width: 9rem"
          class="border-none text-2xl text-center date-input bg-secondary text-white rounded-md py-0"
          :placeholder="'Month'"
          :inputFormat="'MM.yyyy'"
          :minimumView="'month'"
          :maximumView="'year'"
          :initialView="'year'"
          :locale="lan"
          v-model="movementDate"
        ></datepicker>
        <button @click="nextMonth">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
        <!-- <button @click="print">print</button> -->
      </div>

      <div class="auflistung-side col-span-2 animate__animated animate__fadeInUp xl:order-none order-3">
        <!-- Monatliche gesamt Auflistung - ändert sich bei Datumsänderung vom Datepicker -->
        <div class="content-title listtophover listtopactive flex justify-between self-center text-xl font-light border-2 border-secondary shadow rounded-md py-1 px-5 cursor-pointer">
          <h2>{{ $t("Overviews") }}</h2>
          <span class="show-icon show print-display text-secondary" @click="showTables = !showTables">
            <ChevronDownIcon class="h-6 w-6 text-secondary" v-if="showTables" />
            <ChevronUpIcon class="h-6 w-6" v-else />
          </span>
        </div>
        <div v-if="showTables">
          <div class="auflistung-side-inner border-2 border-secondary rounded xs:p-5 overflow-x-auto mt-5">
            <div class="w-full text-center">
              <span class="font-semibold bg-primary px-3 rounded-t-md">
                {{ `01.${(movementDate.getMonth() + 1).toString().padStart(2, "0")}.${movementDate.getFullYear()}` }}
                -
                {{ `${this.endMonthDay()}.${(movementDate.getMonth() + 1).toString().padStart(2, "0")}.${movementDate.getFullYear()}` }}
              </span>
            </div>
            <table class="table w-345 xs:w-full anfang mx-auto px-1">
              <tbody class="w-full">
                <tr>
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-bold print-text-center-border">
                    {{ $t("Start stand") }}
                  </td>
                  <td
                    class="border border-emerald-500 px-4 text-emerald-600 font-bold print-text-center-border text-right pr-5"
                    :class="{
                      'text-green-500': Math.sign(movementData.beforeTotalAmount) == true,
                      'text-gray-900': Math.sign(movementData.beforeTotalAmount) === 0,
                      'text-red-500': Math.sign(movementData.beforeTotalAmount) == -1,
                    }"
                  >
                    {{ (+movementData.beforeTotalAmount + +movementData.totalAccountsCredit).toLocaleString("de-DE", { minimumFractionDigits: 2 }) }}
                  </td>
                </tr>
                <tr>
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium print-text-center-border">
                    {{ $t("of which reserves") }}
                  </td>
                  <td class="border border-emerald-500 px-4 text-emerald-600 font-medium print-text-center-border text-right pr-5">
                    {{ parseFloat(movementData.beforeTotalSaves).toLocaleString("de-DE", { minimumFractionDigits: 2 }) }}
                  </td>
                </tr>
                <tr class="bg-emerald-200">
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-bold print-text-center-border">
                    {{ $t("Total revenue") }}
                  </td>
                  <td
                    class="border border-emerald-500 px-4 text-emerald-600 font-medium text-right pr-5 print-text-center-border"
                    :class="{
                      'text-green-500': Math.sign(movementData.incomes) == true,
                      'text-gray-900': Math.sign(movementData.incomes) === 0,
                      'text-red-500': Math.sign(movementData.incomes) == -1,
                    }"
                  >
                    {{
                      parseFloat(movementData.incomes).toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-bold print-text-center-border">
                    {{ $t("Total expenses") }}
                  </td>
                  <td class="border border-emerald-500 px-4 text-emerald-600 font-medium text-red-500 print-text-center-border text-right pr-5">
                    {{ movementData.expenses !== 0 ? "-" : "" }}
                    {{ parseFloat(movementData.expenses).toLocaleString("de-DE", { minimumFractionDigits: 2 }) }}
                  </td>
                </tr>
                <tr>
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium print-text-center-border">
                    {{ $t("Total saves") }}
                  </td>
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-bold print-text-center-border text-right pr-5">
                    {{
                      parseFloat(movementData.saves).toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </td>
                </tr>

                <tr>
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-bold print-text-center-border">
                    {{ $t("end result") }}
                  </td>
                  <td
                    class="border border-emerald-500 px-4 py-2 text-emerald-600 font-bold text-right pr-5 print-text-center-border"
                    :class="{
                      'text-green-500': Math.sign(calculateTotal(movementData)) == true,
                      'text-gray-900': Math.sign(calculateTotal(movementData)) === 0,
                      'text-red-500': Math.sign(calculateTotal(movementData)) == -1,
                    }"
                  >
                    {{
                      calculateTotal(movementData).toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium print-text-center-border">
                    {{ $t("From reserves") }}
                  </td>
                  <td class="border border-emerald-500 px-4 text-emerald-600 font-medium text-right pr-5 print-text-center-border">
                    {{ parseFloat(movementData.balancedTotalSaves).toLocaleString("de-DE", { minimumFractionDigits: 2 }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="accountOverview.length" class="auflistung-side-inner border-2 border-secondary rounded xs:p-5 mt-2 overflow-x-auto">
            <table class="table w-345 xs:w-full anfang mx-auto px-2">
              <thead>
                <th class="text-right">{{ $t("Start stand") }}</th>
                <th class="text-right">{{ $t("end result") }}</th>
              </thead>
              <tbody class="w-full" v-for="(account, index) in accountOverview" :key="index">
                <tr class="bg-primary w-full capitalize rounded-t-md">
                  <td class="font-semibold" colspan="2">
                    {{ $t(account.name) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="border border-emerald-500 text-emerald-600 font-semibold text-right print-text-center-border"
                    :class="{
                      'text-green-500': Math.sign(account.totalBefore) == true,
                      'text-gray-900': Math.sign(account.totalBefore) === 0,
                      'text-red-500': Math.sign(account.totalBefore) == -1,
                    }"
                  >
                    {{
                      parseFloat(account.totalBefore).toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </td>
                  <td
                    class="border border-emerald-500 text-emerald-600 font-semibold text-right print-text-center-border"
                    :class="{
                      'text-green-500': Math.sign(account.totalEnd) == true,
                      'text-gray-900': Math.sign(account.totalEnd) === 0,
                      'text-red-500': Math.sign(account.totalEnd) == -1,
                    }"
                  >
                    {{
                      parseFloat(account.totalEnd).toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="search-container" class="my-5 w-full mx-auto md:ml-auto print-display hidden md:block">
          <div class="relative mx-auto text-secondary">
            <button :disabled="search == ''" @click="allSearch" class="absolute h-10 mt-1 right-0 top-0 flex items-center pr-4">
              <svg class="h-4 w-4 fill-current text-secondary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
              </svg>
            </button>
            <input
              id="search-toggle"
              :placeholder="$t('Bewegung suchen')"
              v-model="search"
              @keyup.enter="allSearch"
              class="block w-full bg-brand-white focus:outline-none border-secondary border-2 focus:border-secondary focus:ring-primary focus:bg-white focus:shadow text-secondary font-light rounded-lg pl-4 pr-12 py-3"
            />
          </div>
          <button @click.prevent="advancedSearch = true" class="float-right text-sm mt-1 text-secondary">
            {{ $t("Advanced Search") }}
          </button>
        </div>
      </div>
      <div class="auflistung-main col-span-3 animate__animated animate__fadeInRight">
        <div class="effektive-bewegungen border-b-2 border-gray-300 pb-8 order-2 sm:order-1">
          <div class="content-title listtophover flex justify-between self-center text-xl font-light border-2 border-secondary shadow rounded-md py-1 px-5 cursor-pointer">
            <h2 v-if="!filterData">{{ $t("Effective Movement") }}</h2>
            <div v-if="filterData" class="flex items-center justify-center">
              <p class="text-red-500">
                {{ $t("Filtered Effective Transfers") }}
              </p>
              <button @click="refreshData()" class="flex items-center">
                <p class="font-semibold text-sm text-red-500 ml-5">
                  {{ $t("remove Filter") }}
                </p>
              </button>
            </div>
            <span class="show-icon hide print-display text-secondary" @click="isEffectiveOpen = !isEffectiveOpen">
              <ChevronDownIcon class="h-6 w-6" v-if="isEffectiveOpen" />
              <ChevronUpIcon class="h-6 w-6" v-else />
            </span>
          </div>

          <collapse-transition dimension="height">
            <section class="ef-bewegungen" v-show="isEffectiveOpen">
              <div class="add mt-5">
                <button
                  type="button"
                  @click="openModal({}, 'new')"
                  title="neue Bewegungen erstellen"
                  class="shadow-lg flex items-center text-lg border border-secondary bg-secondary text-white py-1 px-2 rounded-lg hover:text-secondary hover:bg-white hover:border-primary print-display"
                >
                  <span class="sm:block">{{ $t("New Movement") }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </button>
              </div>
              <div class="flex w-full mt-5">
                <div id="search-container" class="print-display block md:hidden w-full mx-2">
                  <div class="relative mx-auto text-secondary">
                    <button :disabled="search == ''" @click="allSearch" class="absolute h-10 mt-1 right-0 top-0 flex items-center pr-4">
                      <svg class="h-4 w-4 fill-current text-secondary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                      </svg>
                    </button>
                    <input
                      id="search-toggle"
                      :placeholder="$t('Bewegung suchen')"
                      v-model="search"
                      @keyup.enter="allSearch"
                      class="block w-full bg-brand-white focus:outline-none border-secondary border-2 focus:border-secondary focus:ring-primary focus:bg-white focus:shadow text-secondary font-light rounded-lg pl-4 pr-12 py-3"
                    />
                  </div>
                  <button @click.prevent="advancedSearch = true" class="float-right text-sm mt-1 text-secondary">
                    {{ $t("Advanced Search") }}
                  </button>
                </div>
              </div>
              <div class="overflow-x-auto flex flex-col items-center">
                <div class="ef-bewegungen-top-row w-full sm:w-full">
                  <div class="text-xs justify-between text-secondary mb-4 mt-4 rounded border-2 border-secondary px-5 py-2 grid grid-cols-3  sm:grid-cols-3 lg:grid-cols-5  gap-2 geplante print-flex">
                    <div class="ef-bew-cat col-span-1 print-w-4 print-text-lg">
                      <span class="flex items-center space-x-1 md:space-x-2">
                        <span class="">{{ $t("Date") }}</span>
                        <div class="flex items-center space-x-0 md:space-x-1 print-display">
                          <button @click="sortAscDesc('Desc')">
                            <SortAscendingIcon class="h-4 w-4" :class="sortActive ? 'text-secondary' : 'text-primary'" />
                          </button>
                          <button @click="sortAscDesc('ASC')">
                            <SortDescendingIcon :class="!sortActive ? 'text-secondary' : 'text-primary'" class="h-4 w-4" />
                          </button>
                        </div>
                      </span>
                    </div>
                    <div class="ef-bew-cat col-span-1 print-w-4 print-text-lg">
                      <span>{{ $t("Category") }}</span>
                    </div>

                    <div class="ef-bew-betrag col-span-1 text-right geplante-item print-w-4 print-text-lg print-text-right">
                      <span>{{ $t("Person") }}</span>
                    </div>
                     <div class="ef-bew-betrag col-span-1 hidden lg:block text-right geplante-item print-w-4 print-text-lg print-text-right">
                      <span>{{ $t("Konto") }}</span>
                    </div>
                     <div class="ef-bew-betrag col-span-1 hidden lg:block text-right geplante-item print-w-4 print-text-lg print-text-right">
                      <span>{{ $t("amount in €") }}</span>
                    </div>
                  </div>
                </div>

                <div role="listbox" aria-labelledby="ef-bewegungen-label" id="ef-bewegungen" class="print-list-style w-full">
                  <div class="bewegungen-hit sm:w-full" role="option" aria-selected="false" v-for="movement in movementList.data" :key="movement.id">
                    <div @click="openModal(movement, 'update')" class="cursor-pointer bewegungen-inner grid grid-cols-3  sm:grid-cols-3 lg:grid-cols-5 gap-2 geplante-list geplante print-flex text-gray-900 font-semibold">
                      <div class="ef-bew-cat col-span-1 geplante-item print-font-date print-w-4">
                        <span>{{ convertDate(movement.date) }}</span>
                      </div>
                      <div class="ef-bew-cat col-span-1 geplante-item print-w-4">
                        <span>{{ movement.category ? $t(movement.category.name) : "" }}</span>
                      </div>
                       <div class="ef-bew-cat col-span-1 geplante-item print-w-4 hidden lg:block text-right  print-w-4 print-text-lg print-text-right">
                        <span>{{ movement.person ? movement.person.name  : $t("together")  }}</span>
                      </div>
                      <div class="ef-bew-cat col-span-1 geplante-item print-w-4 hidden lg:block text-right  print-w-4 print-text-lg print-text-right">
                        <span>{{ movement.account_id ? movement.account_id.name : '-'   }}</span>
                      </div>
                      <div class="ef-bew-betrag col-span-1 text-right geplante-item print-w-4 print-text-right">
                        <span v-if="movement.category" class="font-semibold" :class="!movement.category.cat_in_out ? 'text-red-500' : 'text-green-500'">
                          {{ !movement.category.cat_in_out ? "-" : "" }}
                          {{
                            parseFloat(movement.amount).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </span>
                      </div>

                      <!-- <div
                        class="ef-bew-pers col-span-1 text-center geplante-item"
                      >
                        <span>{{
                          movement.person
                            ? movement.person.name
                            : $t("together")
                        }}</span>
                      </div>
                      <div
                        class="ef-bew-pers col-span-1 text-center geplante-item"
                      >
                        <span>{{
                          movement.account_id ? movement.account_id.name : "-"
                        }}</span>
                      </div>
                      <div class="ef-bew-pers col-span-1 text-center truncate">
                        <span>{{
                          movement.comment ? movement.comment : "-"
                        }}</span>
                      </div>
                      <div
                        class="
                          ef-bew-action
                          flex
                          col-span-1
                          justify-end
                          print-display
                        "
                      >
                        <div class="edit">
                          <button @click="openModal(movement, 'update')">
                            <PencilAltIcon class="h-6 w-6 text-fuchsia-600" />
                          </button>
                        </div>
                        <div class="delete">
                          <button @click="deleteMovement(movement.id)">
                            <TrashIcon class="h-6 w-6 text-red-400" />
                          </button>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- pagination start-->
              <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 print-display" aria-label="Pagination">
                <div class="hidden sm:block">
                  <p class="text-sm text-gray-700">
                    {{ $t("Showing") }}
                    <span class="font-medium">{{ movementList.from }}</span>
                    {{ $t("to") }}
                    <span class="font-medium">{{ movementList.to }}</span>
                    {{ $t("of") }}
                    <span class="font-medium">{{ movementList.total }}</span>
                    {{ $t("results") }}
                  </p>
                  <div class="text-sm text-gray-700 ml-3 mt-2">
                    {{ $t("show per page") }}
                    <select @change="formatCountPagination" v-model="movementCount" class="rounded-md border-gray-300">
                      <option :selected="movementCount == 10" value="10">10</option>
                      <option :selected="movementCount == 20" value="20">20</option>
                      <option :selected="movementCount == 30" value="30">30</option>
                      <option :selected="movementCount == 1000" value="1000">{{ $t("All") }}</option>
                    </select>
                  </div>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                  <button
                    @click="firstPageMovementList"
                    :class="
                      !movementList.prev_page_url
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="mr-1 relative inline-flex items-center px-1 py-2"
                  >
                    <ChevronDoubleLeftIcon class="h-4 w-4" />
                  </button>
                  <button
                    @click="prePageMovementList"
                    :disabled="!movementList.prev_page_url"
                    :class="
                      !movementList.prev_page_url
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="relative inline-flex items-center px-4 py-2"
                  >
                    {{ $t("Previous") }}
                  </button>
                  <button
                    @click="nextPageMovementList"
                    :disabled="!movementList.next_page_url"
                    :class="
                      !movementList.next_page_url
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="ml-3 relative inline-flex items-center px-4 py-2"
                  >
                    {{ $t("Next") }}
                  </button>
                  <button
                    @click="nextLastPageMovementList"
                    :class="
                      !movementList.next_page_url
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="ml-1 relative inline-flex items-center px-1 py-2"
                  >
                    <ChevronDoubleRightIcon class="h-4 w-4" />
                  </button>
                </div>
              </nav>
              <!-- pagination end-->
              <div class="main-action p-2 gap-2 text-sm text-secondary rounded mt-5 flex justify-between">
                <div class="add print-display">
                  <button type="button" @click="exportCsv" title="export" class="shadow-lg flex items-center text-lg border-2 bg-secondary text-white py-1 px-2 rounded-lg hover:text-secondary hover:bg-white hover:border-secondary">
                    <span class="sm:block">{{ $t("export") }}</span>
                    <DocumentDownloadIcon class="h-6 w-6" />
                  </button>
                </div>
              </div>
            </section>
          </collapse-transition>
        </div>

        <div class="geplante-bewegungen mb-8 border-b-2 border-gray-300 pb-8 order-3 sm:order-2">
          <div class="content-title listtophover listtopactive flex justify-between self-center text-xl font-light border-2 border-secondary shadow rounded-md py-1 px-5 cursor-pointer">
            <h2>{{ $t("Planned Movement") }}</h2>
            <span class="show-icon show print-display text-secondary" @click="isPlanningOpen = !isPlanningOpen">
              <ChevronDownIcon class="h-6 w-6" v-if="isPlanningOpen" />
              <ChevronUpIcon class="h-6 w-6" v-else />
            </span>
          </div>
          <collapse-transition dimension="height">
            <section class="gp-bewegungen" v-show="isPlanningOpen">
              <div class="overflow-x-auto flex flex-col items-center">
                <div class="gp-bewegungen-top-row w-full sm:w-full">
                  <div class="text-xs justify-between text-secondary mb-4 mt-4 rounded border-2 border-secondary px-5 py-2 grid grid-cols-2 gap-2 print-flex print-flex-row print-justify-between">
                    <div class="gp-bew-ck col-span-1 geplante-item print-tal print-text-lg ">
                      <span>{{ $t("Category") }}</span>
                    </div>
                    <div class="gp-bew-betrag col-span-1 text-right geplante-item cursor-pointer w-full flex ">
                      <div class="w-full text-right grid grid-cols-2 geplante-item print-tar ">
                        <span class="font-semibold text-right col-span-1 print-text-lg">{{ $t("amount in €") }}</span>
                      </div>
                      <div class="print-display">
                        <span>{{ $t("action") }}</span>
                      </div>
                    </div>
                    <!-- <div
                      class="gp-bew-pers col-span-1 text-center geplante-item"
                    >
                      <span>{{ $t("Person") }}</span>
                    </div>
                    <div
                      class="gp-bew-pers col-span-1 text-center geplante-item"
                    >
                      <span>{{ $t("Account") }}</span>
                    </div>
                    <div
                      class="gp-bew-pers col-span-1 text-center geplante-item"
                    >
                      <span>{{ $t("comment") }}</span>
                    </div>
                    <div
                      class="gp-bew-show col-span-1 text-right print-display"
                    >
                      <span>{{ $t("action") }}</span>
                    </div> -->
                  </div>
                </div>

                <div role="listbox" aria-labelledby="gp-bewegungen-label" id="gp-bewegungen" class="print-list-style w-full print-mt-1">
                  <div class="bewegungen-hit items-center print-list-style w-full sm:w-full" aria-selected="false" v-for="plan in effectivePlanningListPaginate" :key="plan.id">
                    <div class="bewegungen-inner items-center grid grid-cols-2 gap-2 print-flex print-flex-row print-justify-between">
                      <div class="gp-bew-cat col-span-1 flex geplante-list">
                        <input id="offers" v-model="selectedPlans" :value="plan.id" aria-describedby="offers-description" name="offers" type="checkbox" class="focus:ring-secondary h-4 w-4 text-secondary border-gray-300 rounded print-display mr-4" />
                        <span @click="openPlanModal(plan, 'update')" class="geplante-item cursor-pointer w-full">
                          {{ plan.category ? $t(plan.category.name) : "" }}
                        </span>
                      </div>
                      <!-- <div class="gp-bew-betrag text-center geplante-item">
                        <span>
                          {{
                            plan.category
                              ? plan.category.cat_in_out
                                ? "+"
                                : "-"
                              : ""
                          }}
                        </span>
                      </div> -->
                      <div class="gp-bew-betrag col-span-1 text-right geplante-item cursor-pointer w-full flex justify-between">
                        <div @click="openPlanModal(plan, 'update')" class="w-full text-right grid grid-cols-2">
                          <span class="font-semibold text-right col-span-1 print-tar" :class="!plan.category.cat_in_out ? 'text-red-500' : 'text-green-500'">
                            {{ !plan.category.cat_in_out ? "-" : "" }}
                            {{
                              parseFloat(plan.amount).toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                              })
                            }}
                          </span>
                        </div>

                        <div class="move print-display">
                          <button @click="setEffective(plan)">
                            <ClipboardCopyIcon class="h-6 w-6 text-blue-400" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- pagination start -->
              <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 print-display" aria-label="Pagination">
                <div class="hidden sm:block">
                  <p class="text-sm text-gray-700">
                    {{ $t("Showing") }}
                    <span class="font-medium">{{ paginationPages.length ? firstOfList : 0 }}</span>
                    {{ $t("to") }}
                    <span class="font-medium">{{ endOfList }}</span>
                    {{ $t("of") }}
                    <span class="font-medium">{{ effectivePlanningList.length }}</span>
                    {{ $t("results") }}
                  </p>
                  <div class="text-sm text-gray-700 ml-3 mt-2">
                    {{ $t("show per page") }}
                    <select @change="formatCountPagination" v-model="paginationPerPage" class="rounded-md border-gray-300">
                      <option :selected="paginationPerPage == 10" value="10">10</option>
                      <option :selected="paginationPerPage == 20" value="20">20</option>
                      <option :selected="paginationPerPage == 30" value="30">30</option>
                      <option :selected="paginationPerPage == 1000" value="1000">{{ $t("All") }}</option>
                    </select>
                  </div>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                  <button
                    @click="paginationPage = 1"
                    :disabled="paginationPage == 1"
                    :class="
                      paginationPage == 1
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="mr-1 relative inline-flex items-center px-1 py-2"
                  >
                    <ChevronDoubleLeftIcon class="h-4 w-4" />
                  </button>
                  <button
                    @click="paginationPage = 1"
                    :disabled="paginationPage == 1"
                    :class="
                      paginationPage == 1
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="relative inline-flex items-center px-4 py-2"
                  >
                    {{ $t("Previous") }}
                  </button>
                  <button
                    @click="paginationPage++"
                    :disabled="paginationPage == paginationPages.length || !paginationPages.length"
                    :class="
                      paginationPage == paginationPages.length || !paginationPages.length
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="ml-3 relative inline-flex items-center px-4 py-2"
                  >
                    {{ $t("Next") }}
                  </button>
                  <button
                    @click="paginationPage = paginationPages.slice(-1)"
                    :disabled="paginationPage == paginationPages.length || !paginationPages.length"
                    :class="
                      paginationPage == paginationPages.length || !paginationPages.length
                        ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                        : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
                    "
                    class="ml-1 relative inline-flex items-center px-1 py-2"
                  >
                    <ChevronDoubleRightIcon class="h-4 w-4" />
                  </button>
                </div>
              </nav>

              <div class="main-action p-2 gap-2 text-sm text-secondary rounded mt-5 flex justify-between">
                <div class="flex gap-5 print-display">
                  <div class="move print-display">
                    <button
                      :disabled="selectedPlans.length <= 1"
                      :class="
                        selectedPlans.length <= 1
                          ? 'border border-secondary bg-secondary text-white py-1 px-2 rounded-lg font-light print-display opacity-40 cursor-not-allowed '
                          : 'border border-secondary bg-secondary text-white py-1 px-2 rounded-lg font-light print-display'
                      "
                      @click="setEffective(selectedPlans)"
                      title="Bewegung zu effektiven verschieben"
                      class="shadow-lg flex items-center text-lg"
                    >
                      <span class="sm:block">{{ $t("Move to effective") }}</span>
                      <ClipboardCopyIcon class="h-6 w-6" />
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </collapse-transition>
        </div>
      </div>
    </div>
    <modal
      :open="showModal"
      @close="
        () => {
          showModal = false;
          fetchData();
          selectedPlans.length = 0;
        }
      "
      @fetchData="fetchData()"
      :type="type"
      :data="movement"
      :mode="mode"
    ></modal>
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="advancedSearch">
      <SearchBox :dateSource="movementDate" @close="this.advancedSearch = false" @closeAndFilter="closeAdvancedSearch" />
    </div>
  </div>
  <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" :loader="'dots'" :color="'#963d97'" />
</template>

<script>
const monthList = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
import Modal from "@/components/Modal";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { ClipboardCopyIcon, ChevronDownIcon, ChevronUpIcon, DocumentDownloadIcon, SortAscendingIcon, SortDescendingIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from "@heroicons/vue/outline";
import SearchBox from "@/components/SearchBox.vue";
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import { Printd } from "printd";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Modal,
    ClipboardCopyIcon,
    CollapseTransition,
    ChevronDownIcon,
    ChevronUpIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    DocumentDownloadIcon,
    SearchBox,
    Datepicker,
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
    Loading,
  },
  data() {
    return {
      showModal: false,
      type: null,
      movement: null,
      mode: null,
      search: "",
      monthList,
      selectedPlans: [],
      isEffectiveOpen: true,
      isPlanningOpen: true,
      advancedSearch: false,
      filterData: false,
      movementDate: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
      sort: "",
      page: 1,
      sortActive: true,
      showTables: true,
      paginationPerPage: 10,
      paginationPages: [],
      paginationPage: 1,
      firstOfList: 0,
      endOfList: 0,
      isLoading: false,
      startDateSearch: null,
      endDateSearch: null,
      movementCount: 10,
    };
  },
  computed: {
    movementList() {
      return this.$store.state.movement.movementList || [];
    },
    movementData() {
      return this.$store.state.movement.movementData;
    },
    effectivePlanningList() {
      return this.$store.state.planning.effectivePlanningList.data || [];
    },
    accountOverview() {
      return this.$store.state.accounts.accountOverview;
    },
    effectivePlanningListPaginate() {
      return this.paginate(this.effectivePlanningList);
    },
    searchFilters() {
      return this.$store.state.movement.searchFilters;
    },
  },
  watch: {
    movementDate: function () {
      this.refreshData();
      this.$store.commit("setMainDate", this.movementDate);
    },
    movementList() {
      this.selectedPlans.length = 0;
    },

    effectivePlanningList() {
      this.paginationPages.length = 0;
      this.paginationPage = this.paginationPages.length + 1;
      this.setPages();
      this.paginate(this.effectivePlanningList);
      this.paginateNumberShow();
      this.isLoading = false;
    },
    paginationPage: function () {
      this.paginateNumberShow();
    },

    sort: function () {
      this.page = 1;
      this.getMovementList();
    },
  },
  methods: {
    formatCountPagination() {
      this.refreshData();
    },
    exportCsv() {
      // console.log(this.searchFilters);
      var from = '';
      var to = '';

      if(this.searchFilters.start){
        from = this.formatDate(this.searchFilters.start)
        to = this.formatDate(this.searchFilters.end)
      } else {
        from = `${this.movementDate.getFullYear()}-${(this.movementDate.getMonth() + 1).toString().padStart(2, "0")}-01`;
        to = `${this.movementDate.getFullYear()}-${(this.movementDate.getMonth() + 1).toString().padStart(2, "0")}-${this.endMonthDay()}`;
      }

      this.$store.dispatch("movement/exportCv", {
        from,
        to,
        language: localStorage.getItem("haushaltsLang") ? localStorage.getItem("haushaltsLang") : "de",
        account_id: this.searchFilters?.account_id,
        amount: this.searchFilters?.amount,
        category_id: this.searchFilters?.category_id,
        person_id: this.searchFilters?.person_id,
        search: this.searchFilters?.search,
      });
    },
    formatDate(dateString) {
        const parts = dateString.split("-");
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; 
        const day = parseInt(parts[2], 10);

        const date = new Date(year, month, day);
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        return formattedDate;
      },
    openModal(movement, mode) {
      this.$store.dispatch("User/GetCategory").then(() => {
        this.$store.dispatch("User/getPersons").then(() => {
          this.$store.dispatch("accounts/getAccountList", { count: 100 }).then(() => {
            this.showModal = true;
            this.movement = movement;
            this.mode = mode;
            this.type = "MovementModal";
          });
        });
      });
    },
    openPlanModal(plan, mode) {
      this.$store.dispatch("User/GetCategory").then(() => {
        this.$store.dispatch("User/getPersons").then(() => {
          this.$store.dispatch("accounts/getAccountList").then(() => {
            this.showModal = true;
            this.movement = plan;
            this.mode = mode;
            this.type = "PlanningModal";
          });
        });
      });
    },
    getMovementList() {
      this.isLoading = true;
      this.$store
        .dispatch("movement/getMovementList", {
          start: this.startDateSearch !== null ? this.startDateSearch : this.startDate(),
          end: this.endDateSearch !== null ? this.endDateSearch : this.endDate(),
          search: this.search,
          sort: this.sort,
          page: this.page,
          count: this.movementCount,
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getEffectivePlanningList() {
      this.$store.dispatch("planning/getEffectivePlanningList", {
        from: this.startDate(),
        to: this.endDate(),
        count: this.paginationPerPage,
      });
    },
    deleteMovement(id) {
      this.$store.dispatch("movement/DeleteMovement", id).then(() => {
        this.fetchData();
      });
    },
    setEffective(data) {
      this.$store.dispatch("accounts/getAccountList").then(() => {
        this.showModal = true;
        this.movement = data;
        this.type = this.selectedPlans.length > 1 ? "effectiveModal" : "SingleEffectiveModal";
        this.mode = "planningMove";
      });
    },
    deletePlan(data) {
      typeof data == Object ? Object.values(data).join(",") : data;
      this.$store.dispatch("planning/DeletePlan", data);
    },
    calculateTotal(movementData) {
      return +movementData.beforeTotalAmount + +movementData.totalAccountsCredit + +movementData.totalAmount;
    },
    endMonthDay() {
      const endDate = new Date(this.movementDate.getFullYear(), this.movementDate.getMonth() + 1, 0);
      return endDate.getDate();
    },
    getAccountsInfo() {
      this.$store.dispatch("accounts/getAccountOverview", {
        start: this.startDate(),
        end: this.endDate(),
      });
    },
    convertDate(date) {
      let year = new Date(date).getFullYear();
      let month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
      let day = new Date(date).getDate().toString().padStart(2, "0");
      return `${day}-${month}-${year}`;
    },
    closeAdvancedSearch(date) {
      this.startDateSearch = date.start;
      this.endDateSearch = date.end;
      // console.log(date);
      this.advancedSearch = false;
      this.filterData = true;
    },
    fetchData() {
      this.page = 1;
      this.getMovementList();
      this.getEffectivePlanningList();
      this.getAccountsInfo();
      this.isLoading = true;
    },
    refreshData() {
      this.search = "";
      this.filterData = false;
      this.startDateSearch = null;
      this.endDateSearch = null;
      this.fetchData();
      this.$store.dispatch("movement/restSearchFilters");
    },
    startDate() {
      return `${this.movementDate.getFullYear()}-${(this.movementDate.getMonth() + 1).toString().padStart(2, "0")}-01`;
    },
    endDate() {
      return `${this.movementDate.getFullYear()}-${(this.movementDate.getMonth() + 1).toString().padStart(2, "0")}-${this.endMonthDay()}`;
    },
    allSearch() {
      this.fetchData();
      this.filterData = true;
    },
    print() {
      const css = `@media print {
  .print-display {
    display: none !important;
  }
  .print {
    display: flex !important;
    flex-direction: column !important;
  }
  .print-table {
    width: 100% !important;
  }
  .geplante {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    text-align: center !important;
    border: 2px solid !important;
    justify-content: center !important;
  }
  .geplante-item{
    width: 16.666667%;
    text-align: center !important;
  }
  .anfang{
     border: 2px solid !important;
     width: 100% !important;
     margin-top: 4px !important;
  }
  .print-text-center-border{
    text-align: center !important;
     border: 2px solid !important;
  }
  .print-text-center{
    text-align: center !important;
  }
  .geplante-list{
    display: flex !important;
    flex-direction: row  !important;
    border: 2px solid !important;
    padding: 0 !important;
  }
  .print-list-style{
    list-style-type: none !important;
    padding: 0 !important;
    width: 100% !important;
  }
  .print-w-2{
    width: 16.666667% !important;
  }
  .print-w-3{
    width: 25% !important;
  }
  .print-font-date{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
}`;
      const d = new Printd();
      d.print(this.$refs.printMe, [css]);
    },
    sortAscDesc(type) {
      // console.log(type);
      type == "Desc" ? (this.sortActive = true) : (this.sortActive = false);
      this.sort = type;
    },
    nextPageMovementList() {
      if (this.movementList.next_page_url) {
        this.page = this.movementList.current_page + 1;
        this.getMovementList();
      }
    },
    nextLastPageMovementList() {
      if (this.movementList.next_page_url) {
        this.page = this.movementList.last_page;
        this.getMovementList();
      }
    },
    prePageMovementList() {
      if (this.movementList.prev_page_url) {
        this.page = this.movementList.current_page - 1;
        this.getMovementList();
      }
    },
    firstPageMovementList() {
      if (this.movementList.prev_page_url) {
        this.page = 1;
        this.getMovementList();
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.effectivePlanningList.length / this.paginationPerPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.paginationPages.push(index);
      }
    },
    paginate(effectivePlanningList) {
      let page = this.paginationPage;
      let perPage = this.paginationPerPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return effectivePlanningList.slice(from, to);
    },
    paginateNumberShow() {
      this.firstOfList = this.paginationPage * this.paginationPerPage - this.paginationPerPage + 1;
      this.endOfList =
        this.effectivePlanningListPaginate.length < this.paginationPerPage
          ? this.effectivePlanningListPaginate.length + this.paginationPerPage * this.paginationPage - this.paginationPerPage
          : this.effectivePlanningListPaginate.length == this.paginationPerPage
          ? this.effectivePlanningListPaginate.length + this.paginationPerPage * this.paginationPage - this.paginationPerPage
          : this.effectivePlanningListPaginate.length;
    },
    nextMonth() {
      if (this.movementDate.getMonth() == 11) {
        this.movementDate = new Date(this.movementDate.getFullYear() + 1, 0, 1);
      } else {
        this.movementDate = new Date(this.movementDate.getFullYear(), this.movementDate.getMonth() + 1, 1);
      }
    },
    previousMonth() {
      if (this.movementDate.getMonth() == 0) {
        this.movementDate = new Date(this.movementDate.getFullYear() - 1, 11, 1);
      } else {
        this.movementDate = new Date(this.movementDate.getFullYear(), this.movementDate.getMonth() - 1, 1);
      }
    },
  },
  mounted() {
    if (this.$route.query.date) {
      this.movementDate = new Date(this.$route.query.date);
    }
    this.refreshData();
    this.$store.commit("setMainDate", new Date());
    this.fetchData();
    // console.log(this.$route.query.date);
  },
};
</script>

<style>
@media print {
  .print-display {
    display: none !important;
  }
  .print {
    display: flex !important;
    flex-direction: column !important;
  }
  .print-table {
    width: 100% !important;
    flex-direction: row;
    text-align: center !important;
    background-color: brown !important;
  }
}
</style>
